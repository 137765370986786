import { render, staticRenderFns } from "./BoardNav.vue?vue&type=template&id=c51cc812&scoped=true&"
import script from "./BoardNav.vue?vue&type=script&lang=js&"
export * from "./BoardNav.vue?vue&type=script&lang=js&"
import style0 from "./BoardNav.vue?vue&type=style&index=0&id=c51cc812&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c51cc812",
  null
  
)

export default component.exports