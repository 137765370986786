<template>
  <div>
    <b-form-group label="Проект">
      <b-form-input v-model="task.project.name" disabled />
    </b-form-group>

    <task-time v-if="task" :task="task"></task-time>

    <lk-select
      v-if="config && task"
      v-model="task.assignee"
      :options="config.members"
      label="Исполнитель"
    ></lk-select>

    <lk-select
      v-if="config && task"
      v-model="task.author"
      :options="taskAuthorList"
      label="Автор"
      disabled
    ></lk-select>

    <lk-input label="Направление разработки" :cols="4" v-if="dictionaries">
      <template v-slot:field>
        <v-select
          v-model="task.labels"
          :options="dictionaries.labels"
          placeholder="Направление разработки"
          label="name"
        ></v-select>
      </template>
      <template v-slot:value>
        <b-badge
          :style="{ background: task.status.color }"
          v-text="
            Array.isArray(task.labels)
              ? task.labels.length
                ? task.labels[0].name
                : 'Не задано'
              : task.labels.name
          "
        >
        </b-badge>
      </template>
    </lk-input>

    <lk-input label="Epick Link" :cols="4" v-if="task.type.name != 'epic' && dictionaries">
      <template v-slot:field>
        <v-select
          v-model="task.epic"
          :options="dictionaries.epics"
          label="name"
          placeholder="Выберите эпик"
        ></v-select>
      </template>
      <template v-slot:value>
        <router-link
          v-if="task.epic"
          :to="{
            name: 'projects-task',
            params: { project: currentProject, task: task.epic.number },
          }"
          target="_blank"
          @click.native.capture.stop
          >{{ task.epic.name }}</router-link
        >
        <b-badge v-else variant="light-dark">Не задано</b-badge>
      </template>
    </lk-input>

    <lk-input label="Sprint" :cols="4" v-if="dictionaries">
      <template v-slot:field>
        <v-select
          v-model="task.sprint"
          :options="dictionaries.sprints"
          placeholder="Выберите спринт"
          label="name"
        ></v-select>
      </template>
      <template v-slot:value>
        <b-badge
          variant="light-dark"
          v-text="task.sprint ? task.sprint.name : 'Не задано'"
        ></b-badge>
      </template>
    </lk-input>

    <lk-input label="Приоритет" :cols="4" v-if="dictionaries">
      <template v-slot:field>
        <v-select
          v-model="task.priority"
          :options="dictionaries.priorities"
          placeholder="Выберите приоритет"
          label="name"
        >
          <template #option="{ name, icon }">
            <span>{{ icon }} {{ name }}</span>
          </template>
        </v-select>
      </template>
      <template v-slot:value>
        <span>{{ task.priority.icon }} {{ task.priority.name }}</span>
      </template>
    </lk-input>

    <lk-input label="Release" :cols="4" >
      <template v-slot:value>
        <router-link
          v-if="task.release"
          :to="{
            name: 'releases',
            query: { search: task.release.name },
          }"
          target="_blank"
          @click.native.capture.stop
          >{{ task.release.name }}</router-link
        >
        <span v-else>Не задано</span>
      </template>
      <template v-slot:field>
        <span class="ml-1">Не задано</span>
      </template>
    </lk-input>

    <lk-input label="Назначение" :cols="4" v-if="dictionaries">
      <template v-slot:field>
        <v-select
          v-model="task.purpose"
          :options="dictionaries.purposes"
          placeholder="Не задано"
          label="name"
        ></v-select>
      </template>
      <template v-slot:value>
        <span v-if="task.purpose">{{ task.purpose.name }}</span>
        <span v-else>Не задано</span>
      </template>
    </lk-input>

    <lk-input label="Story Points" :cols="4">
      <template v-slot:field>
        <b-form-input placeholder="Story Points" type="number" v-model="task.story_point" />
      </template>
      <template v-slot:value>
        <b-badge variant="light-dark">{{ task.story_point }}</b-badge>
      </template>
    </lk-input>

    <lk-input label="Check in build (iOS)" :cols="4">
      <template v-slot:field>
        <b-form-input
          placeholder="Check in build (iOS)"
          v-model="task.check_in_build.ios"
          @keyup="validateIos"
        />
      </template>
      <template v-slot:value>
        <b-badge
          variant="light-dark"
          v-text="task.check_in_build.ios.length ? task.check_in_build.ios : '-'"
        ></b-badge>
      </template>
    </lk-input>

    <lk-input label="Check in build (Android)" :cols="4">
      <template v-slot:field>
        <b-form-input
          placeholder="Check in build (Android)"
          v-model="task.check_in_build.android"
          @keyup="validateAndroid"
        />
      </template>
      <template v-slot:value>
        <b-badge
          variant="light-dark"
          v-text="task.check_in_build.android.length ? task.check_in_build.android : '-'"
        ></b-badge>
      </template>
    </lk-input>

    <div class="mt-1">
      <small>Создано {{ task.created_at | formatDateDefaultWithTime }}</small>
      <br />
      <small v-if="task.updated_at"
        >Дата обновления {{ task.updated_at | formatDateDefaultWithTime }}</small
      >
    </div>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BButton,
  BAvatar,
  BFormTags,
  BProgress,
  BBadge,
  BFormSelect,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import ModalTimeTracking from '../time-tracker/ModalTimeTracking';
import TaskTime from './TaskTime';
import moment from 'moment';
import { mapState } from 'vuex';
import LkInput from '../components/LkInput';
import LkSelect from '../components/LkSelect';

export default {
  name: 'TaskInfo',
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BAvatar,
    BFormTags,
    vSelect,
    BProgress,
    BBadge,
    ModalTimeTracking,
    BFormSelect,
    TaskTime,
    LkInput,
    LkSelect,
  },
  props: {
    task: [Object],
    config: [Object],
    dictionaries: [Object],
  },
  computed: {
    ...mapState('project', ['currentProject']),
  },
  data: () => ({
    taskEpicLink: null,
    taskProjectList: JSON.parse(localStorage.getItem('projects')),
    taskAuthorList: [
      {
        avatar:
          'https://ui-avatars.com/api?name=um&background=B1D4E0&color=0C2D48&size=256&rounded=1&uppercase=0',
        firstname: 'User',
        id: 1000,
        lastname: 'Mail',
      },
    ],
    taskSprint: null,
    isShowEpicList: false,
  }),
  methods: {
    validateIos(e) {
      this.task.check_in_build.ios = e.target.value.replace(/[^0-9\.]/g, '');
    },
    validateAndroid(e) {
      this.task.check_in_build.android = e.target.value.replace(/[^0-9\.]/g, '');
    },
  },
};
</script>
