<template>
  <div v-if="sprint">
    <div class="d-flex align-items-center justify-content-end">
      <b-button
        v-ripple.400="'rgba(40, 199, 111, 0.15)'"
        variant="flat-secondary"
        class="btn-icon mr-1"
      >
        <feather-icon icon="StarIcon" />
      </b-button>
      <div class="d-flex align-items-center cursor-pointer" id="popover-target-1">
        <feather-icon icon="WatchIcon" />
        <div>Прогнозируемая дата окончания: {{ sprint.expired_at | formatDateDefault }}</div>
      </div>
  
      <b-popover target="popover-target-1" triggers="hover" placement="bottom">
        <small class="text-muted">Осталось дней:</small> {{ reminder}}
        <br> <small class="text-muted">Прогнозируемая дата окончания</small>
        <br> {{ sprint.expired_at | formatDateDefaultWithTime }}
        <br> <small class="text-muted">Дата начала</small>
        <br> {{ sprint.started_at | formatDateDefaultWithTime }}
        
      </b-popover>
  
      <b-button variant="outline-primary" class="ml-1 mr-1" @click="stopSprint">Завершить спринт</b-button>
    </div>
    
    <div class="d-flex mt-2 flex-wrap">
      <h2 class="pr-1 mb-0">{{ sprint.name }}</h2>
      <div class="d-flex align-items-center">
        <!-- Searchn Input -->
        <b-input-group
          class="input-group-merge ys-search-input mr-2"
          size="sm"
        >
          <b-form-input placeholder="Поиск... " v-model="search" />
          <b-input-group-append is-text>
            <feather-icon icon="SearchIcon" />
          </b-input-group-append>
        </b-input-group>
      
        <!-- Avatar Group -->
        <b-avatar-group size="32px" v-if="members">
          <b-avatar
            v-for="member in members"
            v-b-tooltip.hover
            :class="{'pull-up': true, 'active': activeAssigneeList.includes(member.id)}"
            :title="member.firstname + member.lastname"
            :src="member.avatar"
            :key="member.id"
            @click.native="searchAssignee(member)"
          />
        <b-avatar
          v-b-tooltip.hover
          :class="{'pull-up': true, 'active': activeAssigneeList.includes(null)}"
          title="Не назначен"
          @click.native="searchAssignee({id:null})"
          variant="secondary"
        >
        <feather-icon icon="UserIcon" />
        </b-avatar>
        </b-avatar-group>
  
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          :variant="activeMyTask ? 'outline-primary' : 'outline-secondary'"
          pill
          size="sm"
          class="ml-2"
          @click="filterMyTask"
        >
          Только мои задачи
        </b-button>
        
        <div>
          <b-button
            v-for="filter in configFiltres"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            :variant="usedFilterList.includes(filter) ? 'primary' : 'outline-secondary'"
            pill
            size="sm"
            class="ml-2 mt-05 mb-05"
            :key="filter.name"
            @click="filterParams(filter)"
          >
            {{ filter.name }}
          </b-button>
        </div>
        <!--<b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          pill
          size="sm"
          class="ml-1"
        >
          Последние обновления
        </b-button>-->
    
      </div>
    </div>
  </div>

</template>

<script>
import {
  BAvatarGroup,
  BAvatar,
  VBTooltip,
  BButton,
  BPopover,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from "moment";
import { mapState } from "vuex";
import NavFilters from "@/views/components/NavFilters";

export default {
    name: "BoardNav",
    components: {
        BAvatarGroup,
        BAvatar,
        BButton,
		    BPopover,
		    BDropdown,
		    BDropdownItem,
		    BFormInput,
		    BInputGroup,
		    BInputGroupAppend,
		    NavFilters
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    props: {
        sprint: [Object],
        members: [Array],
		    configFiltres: [Array]
    },
		data: function() {
				return {
						search: "",
						activeMyTask: false,
            activeAssigneeList: [],
            usedFilterList: [],
				}
		},
		computed: {
				...mapState("user", ["userData"]),
        sprintStartLabel() {
					return (moment().isAfter(this.sprint.started_at)) ? "Начат" : "Начнется"
        },
        reminder() {
          return moment(this.sprint.expired_at).add(1, 'day').diff(moment(), 'day');
        },
		},
    methods: {
    		
		    filterParams(filter) {
		    		if(this.usedFilterList.includes(filter)) {
                this.usedFilterList.splice(this.usedFilterList.indexOf(filter), 1);
            } else {
                this.usedFilterList.push(filter);
            }
            this.$emit('filterSprint', this.activeAssigneeList, this.search, this.usedFilterList);
        },
		    searchAssignee(member) {
            this.activeMyTask = false;
            if(this.activeAssigneeList.includes(member.id)) {
		            this.activeAssigneeList.splice(this.activeAssigneeList.indexOf(member.id), 1);
            } else {
		            this.activeAssigneeList.push(member.id);
            }
            this.$emit('filterSprint', this.activeAssigneeList, this.search, this.usedFilterList);
        },
		    filterMyTask() {
            this.activeAssigneeList = [];
            this.search = "";
				    if(this.activeMyTask) {
						    this.activeMyTask = false;
                this.$emit('filterSprint', [], this.search);
				    } else {
						    this.activeMyTask = true;
						    const member = JSON.parse(this.userData);
                this.$emit('filterSprint', [member.id], this.search, this.usedFilterList);
				    }
		    },
		    stopSprint() {
				    this.$store.dispatch("project/updateOpenSprint", this.sprint)
				    this.$bvModal.show('modal-stop-sprint');
		    },
    },
		watch: {
				search: function (val) {
            this.$emit('filterSprint', this.activeAssigneeList, val, this.usedFilterList);
				},
		},
}
</script>

<style scoped>
  .b-avatar.active {
    box-shadow: 0 0 0 2px #7367f0, inset 0 0 0 1px rgb(34 41 47 / 7%);
  }
</style>
