<template>
  <div>
    <div class="time-tracker" ref="timeTracker" v-if="timerTracker"></div>
    <b-form-group>
      <b-button
        class="w-100 mb-1"
        v-if="!timerTracker"
        variant="success"
        @click="startTracker"
        :disabled="btnStartDisable"
      >
        Начать учет рабочего времени
      </b-button>
  
      <div class="d-flex mb-1 align-items-center" v-if="!timerTracker && task.timer_active">
        <div class="danger-point mr-1"></div>
        <small  >
          Запущен таймер в задаче
          <router-link
            target="_blank"
            :to="{ name: 'projects-task', params: { project: task.timer_active.project_key, task: task.timer_active.task_number }}">
            {{ task.timer_active.project_key }}-{{ task.timer_active.task_number }}
          </router-link>
        </small>
      </div>
     
  
      <b-input-group class="mb-1" size="sm" v-if="timerTracker">
        <b-form-input
          maxlength="100"
          placeholder="Комментарий"
          v-model="timerComment"
          :state="statusSendComment"
          @keyup="sendComment"
        />
        <b-form-valid-feedback tooltip>
          {{ $t("Comment send!") }}
        </b-form-valid-feedback>
      </b-input-group>
      
      <div class="d-flex mb-1" v-if="timerTracker">
        <b-button
          class="w-100 mr-1"
          variant="danger"
          @click="stopTracker"
          :disabled="btnDisable"
        >
          Стоп
        </b-button>
        <b-button
          variant="warning"
          @click="canselTracker"
          :disabled="btnCanselDisable"
          class="btn-icon"
        >
          <feather-icon icon="XIcon" />
        </b-button>
      </div>
      
      <dl class="row mb-0" v-if="taskTracker && taskTracker.history.length">
        <dt class="col-sm-8">{{ taskTracker.history[0].assignee_name }}</dt>
        <dd class="col-sm-4">{{ toFormatFromSec1(taskTracker.history[0].spent) }}</dd>
      </dl>
    </b-form-group>
    
  </div>
</template>

<script>
import { BFormGroup, BButton, BInputGroup, BFormInput, BInputGroupAppend, BFormInvalidFeedback, BFormValidFeedback } from 'bootstrap-vue'
import moment from 'moment';
import { taskApi } from "@/api";
import { mapState } from "vuex";
import { toFormatFromSec1 } from "@/helpers"
import { myWebWorker } from './worker.js'

export default {
  name: "TimeTracker",
  components: {
		  BFormGroup, BButton, BInputGroup, BFormInput, BInputGroupAppend, BFormInvalidFeedback, BFormValidFeedback
  },
  props: {
      task: [Object]
  },
  data: () => ({
      timerTracker: false,
      timerTrackerMoment: null,
      timeStart: null,
		  toFormatFromSec1,
      taskTracker: null,
      btnDisable: false,
		  btnStartDisable: false,
      intervalWorker: null,
		  btnCanselDisable: null,
      timerComment: '',
      isSendComment: null, // Таймер для отправления комментария по keyup
		  statusSendComment: null,
  }),
  computed: {
      ...mapState("project", ["currentProject"])
  },
  methods: {
		  sendComment() {
		  		if(this.timerComment.length > 0) {
            clearInterval(this.isSendComment);
            this.isSendComment = setTimeout(() => {
		            taskApi.timerComment(this.task.timer_active.uuid, this.timerComment).then((res) => {
				            this.statusSendComment = true;
				            setTimeout(()=> {this.statusSendComment = null}, 1000)
		            }).catch((error) => {
				            if(error.response) {
						            this.$bvToast.toast(`Ошибка при отправке комментария. ERROR: ${status} ${message}`, {
								            title: this.$t("Time tracker"),
								            variant: "danger",
								            solid: true,
						            });
				            }
		            })
            }, 1000)
            
          } else {
		  				this.statusSendComment = false;
          }
      },
  		startTracker() {
        this.btnStartDisable = true;
        taskApi.timerStart(this.currentProject, parseInt(this.task.number)).then((res) => {
            this.timerTracker = true;
		        this.task.timer_active = res.data.timer_active;
            // TODO поставить текущее время
            let time = moment("21-12-2022 00:00:00", " HH:mm:ss");
            setTimeout(() => {
                this.$refs.timeTracker.innerHTML = time.format("HH:mm:ss");
            }, 100);
		
		        if (typeof Worker !== undefined) {
				        window.URL = window.URL || window.webkitURL // polyfill
				        // Register the web worker
				        this.intervalWorker = new Worker(
						        window.URL.createObjectURL(myWebWorker),
				        )
				        // Receiving data from the Worker
				        this.intervalWorker.onmessage = (e) => {
						        this.$refs.timeTracker.innerHTML = time.add(1, 'seconds').format("HH:mm:ss");
				        }
		        }
		        this.btnStartDisable = false;
        }).catch((error) => {
		        if(error.response) {
                this.$bvToast.toast(`Ошибка при запуске трекера. ERROR: ${status} ${message}`, {
                    title: this.$t("Time tracker"),
                    variant: "danger",
                    solid: true,
                });
            }
		        this.btnStartDisable = false;
        })
      },
		  stopTracker() {
        this.btnDisable = true;
        this.timerComment = '';
        taskApi.timerFinish(this.currentProject, parseInt(this.task.number)).then(() => {
		        this.timerTracker = false;
		        taskApi.getTimeTracker(this.currentProject, parseInt(this.task.number)).then((res) => {
                this.task.timer_active = null;
		        		this.taskTracker = res.data.time_tracker;
		        		this.$emit('updateSpent', res.data.time_tracker.spent)
            });
		        this.btnDisable = false;
        }).catch((error) => {
		        if(error.response) {
				        this.$bvToast.toast(`Ошибка при остановке трекера. ERROR: ${error.response.data.errors[0].message}`, {
						        title: this.$t("Time tracker"),
						        variant: "danger",
						        solid: true,
				        });
		        }
		        this.btnDisable = false;
        });
        this.intervalWorker.terminate();
		  },
		  canselTracker() {
  				this.btnCanselDisable = true;
          taskApi.timerCancel().then((res) => {
		          this.timerTracker = false;
		          this.task.timer_active = null;
		          if(this.intervalWorker) {
				          this.intervalWorker.terminate();
		          }
          }).catch(() => {
		          if(error.response) {
				          this.$bvToast.toast(`Ошибка при остановке трекера. ERROR: ${error.response.data.errors[0].message}`, {
						          title: this.$t("Time tracker"),
						          variant: "danger",
						          solid: true,
				          });
		          }
          }).then(() => {
		          this.btnCanselDisable = false;
          })
      }
  },
  mounted() {
  		this.taskTracker = this.task.time_tracker;
  		this.timerTracker = !!this.task.timer_active && this.task.timer_active.task_number == this.task.number ;

  		if(this.timerTracker) {
          this.timeStart = moment(this.task.timer_active.started_at);
          let diff = moment().diff(this.timeStart);
          
          let f = moment.utc(diff);
          setTimeout(() => {
              this.$refs.timeTracker.innerHTML = f.format("HH:mm:ss");
          }, 100);
						
          if (typeof Worker !== undefined) {
              window.URL = window.URL || window.webkitURL // polyfill
              // Register the web worker
              this.intervalWorker = new Worker(
                  window.URL.createObjectURL(myWebWorker),
              )
              // Receiving data from the Worker
		          this.intervalWorker.onmessage = (e) => {
		              this.$refs.timeTracker.innerHTML = f.add(1, 'seconds').format("HH:mm:ss");
              }
          }
        
      }
		  this.$on("hook:beforeDestroy", () => {
		  		if(this.intervalWorker) {
              this.intervalWorker.terminate();
          }
		  });
  },
}
</script>

<style>
  [dir] .progress.studio-progress-bar {
      background: #82868b;
      height: 5px;
    }
  [dir] .studio-progress-bar .progress-bar:last-child {
    background-color: #0153cc;
  }
  [dir] .progress.higher-progress-bar {
    background: #ea5455;
    height: 5px;
  }
  [dir] .higher-progress-bar .progress-bars:last-child {
    background-color: #0153cc;
  }

  [dir] .progress.yellow-progress-bar {
    background: #ff9f43;
    height: 5px;
  }

  [dir] .progress.yellow-progress-bar .progress-bar {
    text-align: center;
    background-color: #ff9f43;
  }
  [dir] .yellow-progress-bar .progress-bars:last-child {
    background-color: #ff9f43;
  }
  
  .time-tracker {
    font-size: 26px;
    text-align: center;
    margin-bottom: 10px;
    color: #000;
  }
</style>
