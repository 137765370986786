<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <b-button
        type="submit"
        variant="primary"
        size="xs"
        class="btn-sm"
        @click="isShowEditor = true"
        v-if="!isShowEditor"
      >
        Добавить комментарий
      </b-button>

      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        pill
        size="sm"
        v-if="!isShowEditor"
        @click="sortComment"
      >
        <feather-icon :icon="iconSortBtn" class="mr-50" />
        <span class="align-middle btn-text">{{ textSortBtn }}</span>
      </b-button>
    </div>

    <div v-if="isShowEditor">
      <quill-text-editor v-model="taskComment" :task="task"></quill-text-editor>

      <b-button type="submit" variant="primary" size="xs" class="mt-1 btn-sm" @click="addComment">
        Отправить
      </b-button>
      <b-button
        type="submit"
        variant="outline-secondary"
        size="xs"
        class="mt-1 btn-sm ml-1"
        @click="isShowEditor = false"
      >
        Отмена
      </b-button>
    </div>
    <div v-if="comments">
      <div
        v-for="comment in comments"
        class="d-flex justify-content-start align-items-start mb-1 mt-2"
      >
        <!-- avatar -->
        <b-avatar :src="comment.author.avatar" size="30" class="mr-1" />
        <!--/ avatar -->

        <div v-if="editComment === comment.uuid">
          <quill-text-editor v-model="comment.message" :task="task"></quill-text-editor>
          <b-button
            type="submit"
            variant="primary"
            size="xs"
            class="mt-1 btn-sm"
            @click="saveComment(comment)"
          >
            Отправить
          </b-button>
          <b-button
            type="submit"
            variant="outline-secondary"
            size="xs"
            class="mt-1 btn-sm ml-1"
            @click="editComment = null"
          >
            Отмена
          </b-button>
        </div>
        <div v-else class="profile-user-info">
          <h6 class="mb-0">
            {{ comment.author.firstname }} {{ comment.author.lastname }}
            <small class="text-muted">{{ comment.updated_at | formatDateDefaultWithTime }} {{ checkDateComment(comment) }}</small>
            
          </h6>
          <div class="ql-snow">
            <small class="ql-show" v-html="fixComment(comment)"></small>
          </div>
          <div class="d-flex mt-50">
            <a
              v-if="userId == comment.author.id"
              href="javascript:void()"
              class="mr-1"
              @click="openEditorComment(comment.uuid)"
              >Изменить</a
            >
            <a
              v-if="userId == comment.author.id"
              href="javascript:void()"
              @click="deleteComment(comment.uuid)"
              >Удалить</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, BFormGroup, BButton } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { taskApi } from '@/api';
import { mapState } from 'vuex';
import moment from 'moment';
import QuillTextEditor from '../components/QuillTextEditor';
// import HtmlView from "../components/HtmlView";
// import RenderHtml from "../components/RenderHtml";

export default {
  name: 'TaskComment',
  components: {
    BAvatar,
    BFormGroup,
    BButton,
    QuillTextEditor,
    // RenderHtml,
  },
  directives: {
    Ripple,
  },
  props: {
    task: [Object],
  },
  computed: {
    ...mapState('project', ['currentProject']),
    ...mapState('user', ['userData']),
    userId() {
      return this.userData?.id;
    },
    textSortBtn() {
      return this.sortStatus > 0 ? 'Сначала старые' : 'Сначала новые';
    },
    iconSortBtn() {
      return this.sortStatus > 0 ? 'ArrowDownRightIcon' : 'ArrowUpRightIcon';
    },
    sortType() {
      return this.sortStatus > 0 ? 'desc' : 'asc';
    },
  },
  data: () => ({
    comments: null,
    isShowEditor: false,
    taskComment: '',
    editComment: null,
    sortStatus: 1,
  }),
  methods: {
    fixComment(comment) {
      return comment.message.replaceAll(/<p><\/p>/gi, '<br>');
    },
    sortComment() {
      if (this.sortStatus > 0) {
        this.sortStatus = -1;
        this.comments = this.comments.sort((a, b) => {
          return moment(a.updated_at).isBefore(b.updated_at) ? -1 : 1;
        });
      } else {
        this.sortStatus = 1;
        this.comments = this.comments.sort((a, b) => {
          return moment(a.updated_at).isAfter(b.updated_at) ? -1 : 1;
        });
      }
    },
    addComment() {
      taskApi
        .addComment(this.currentProject, this.task.number, {
          message: this.taskComment,
        })
        .then(() => {
          this.isShowEditor = false;
          this.taskComment = '';
          this.loadComments();
        });
    },
    deleteComment(uuid) {
      taskApi.deleteComment(uuid).then(() => {
        this.loadComments();
      });
    },
    openEditorComment(uuid) {
      this.editComment = uuid;
    },
    checkDateComment(comment) {
       return moment(comment.created_at).isBefore(comment.updated_at) ? "(отредактировано)" : null
    },
    saveComment(comment) {
      // -- костыль для вырезания <p> из <li> это косяк текстового редактора
      comment.message = comment.message.replaceAll(
        /<li><p>(.*?)<\/p><(\/?)(ol|li|ul)>/gi,
        '<li>$1<$2$3>',
      );
      // comment.message = comment.message.replaceAll(/<span><\/span>/gi, '');
      comment.message = comment.message.replaceAll(
        /<\/label><div><p>(.*?)<\/p><\/div><\/li>/gi,
        '</label><div><span>$1</span></div></li>',
      );
      // comment.message = comment.message.replaceAll(/<label/gi, '<label style="margin-right: 1rem; flex: 1" ');
      // comment.message = comment.message.replaceAll(/<li data/gi, '<li style="margin: 0; padding: 0; flex-Direction: row " data');
      // comment.message = comment.message.replaceAll(/ul data/gi, 'ul style="margin: 0; padding: 0 " data');
      // console.log('commentForSave:', comment.message);
      this.editComment = null;
      taskApi
        .editComment(comment.uuid, {
          message: comment.message,
        })
        .then(() => {
          this.loadComments();
        });
    },
    loadComments() {
      taskApi
        .getComments(this.currentProject, this.task.number, {
          created_at: this.sortType,
        })
        .then((res) => {
          this.comments = res.data.task_comments;
          console.log('commits:', this.comments);
        });
    },
  },
  mounted() {
    this.loadComments();
  },
};
</script>

<style scoped></style>
