<template>
  <div class="kanban">
    <template v-if="!loading">
      <div
        v-for="(column, index) in kanbanColumns"
        :key="index"
        :style="columnStyle"
        class="kanban__column"
      >
        <div class="kanban__content" :style="contentStyle">
          <Container
            :group-name="groupname"
            :get-child-payload="(event) => getDraggedItem(event, index)"
            :drop-placeholder="dropPlaceholderOptions"
            @drop="onDrop(index, $event, column)"
            @drag-start="
              sourceContainerIndex = index;
              $emit('drag');
            "
          >
            <Draggable
              v-for="(item, indice) in column.tasks"
              :key="indice"
            >
              <slot name="card" :item="item" />
            </Draggable>
            
          </Container>
        </div>
      </div>
    </template>
    <div v-else class="kanban__skeleton-container">
      <slot name="skeleton" />
      <div v-if="!$slots.skeleton" class="kanban__skeleton">
        <div
          v-for="(i, index) in colSkeleton"
          :key="i"
          class="kanban__skeleton-column"
          :animation-data="index"
          :style="
            `min-width: ${colMinWidth || 180}px; max-width: ${colMaxWidth ||
              330}px`
          "
        >
          <slot name="skeletonCard" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Container, Draggable } from "vue-dndrop";
import { updateTask } from "@/mixins/task";

export default {
    name: "Kanban",
    components: { Container, Draggable },
    props: {
		    groupname: {
				    type: String,
				    default: "Kanban",
		    },
        loading: {
            type: Boolean,
            default: false,
        },
        columns: {
            type: Array,
            default: () => [],
        },
        colWidth: {
            type: [Number, String],
            default: () => "",
        },
        colMinWidth: {
            type: [Number, String],
            default: () => "",
        },
        colMaxWidth: {
            type: [Number, String],
            default: () => "",
        },
        dropIcon: {
            type: String,
            default: () => "copy",
        },
        noBorder: {
            type: Boolean,
            default: false,
        },
        innerColCount: {
            type: Boolean,
            default: false,
        },
        colBgColor: {
            type: String,
            default: () => "",
        },
        countText: {
            type: String,
            default: () => "itens",
        },
    },
    data() {
        return {
            sourceContainerIndex: null,
            kanbanColumns: [],
            dropPlaceholderOptions: {
                className: "drop-test",
                animationDuration: "150",
                showOnTop: true,
            },
		        activeTask: null,
        };
    },
    mixins: [updateTask],
		watch: {
        columns: function (val) {
		        this.kanbanColumns = [...val];
				},
		},
    computed: {
        colSkeleton() {
            const screenSize = window.innerWidth - 300;
            const quantity = screenSize / (this.colMinWidth || 180);
            return Math.ceil(quantity);
        },
        columnStyle() {
            return `width: ${this.colWidth}px;
    min-width: ${this.colMinWidth}px;
    max-width: ${this.colMaxWidth}px;
    `;
        },
        contentStyle() {
            return `background-color: ${this.colBgColor}`;
        },
    },
    mounted() {
        this.kanbanColumns = [...this.columns];
    },
    methods: {
        dropAction(arr, dragResult, column) {
            const { removedIndex, addedIndex, payload } = dragResult;
            if (removedIndex === null && addedIndex === null) return arr;
            const itens = [...arr];
            let itemToAdd = payload;
            if (removedIndex !== null) {
                itemToAdd = itens.splice(removedIndex, 1)[0];
            }
            if (addedIndex !== null) {
                itens.splice(addedIndex, 0, itemToAdd);
                this.$emit("item-dropped", { item: payload });
                /*if (payload.status.length > 1)
                    this.$emit("item-dropped", { item: payload, column: status });
                else if (payload.status !== status)
                    this.$emit("status-change", {
                        item: payload,
                        newStatus: status,
                    });*/
            }
            return itens;
        },
        onDrop(index, dropResult, column) {
		    		// Сохраняем задачу
		    		if(dropResult.addedIndex !== null) {
                this.updateTask(dropResult.payload.number, {
		               workflow_step_id: column.id,
                }, false);
            }
            this.kanbanColumns[index].tasks = this.dropAction(
                this.kanbanColumns[index].tasks,
                dropResult,
                column
            );
        },
        getDraggedItem(item, indice, prop) {
            this.sourceContainerIndex = indice;
            return this.kanbanColumns[indice].tasks[item];
        },
    },
};
</script>

<style scoped>
  .test {
    background: red;
  }
  .test11 {
    background: green;
  }
  /* kanban */
  .kanban {
    display: flex;
    justify-content: stretch;
    width: 100%;
    height: 100%;
    user-select: none;
  }
  .dndrop-container {
    height: 100%;
  }
  .kanban__drop-preview {
    position: relative;
    margin: 10px;
    background-color: #edf1f3;
    border: 2px dashed rgba(0, 0, 0, 0.059);
    border-radius: 4px;
  }
  .kanban__drop-preview::before {
    position: absolute;
    top: 50%;
    left: 50%;
    color: #b0b0b0;
    transform: translate(-50%, -50%);
  }
  .kanban__drop-preview::after {
    position: absolute;
    top: 50%;
    left: 27%;
    width: 20px;
    height: 20px;
    content: "";
    transform: translate(-50%, -50%);
  }
  .kanban__column {
    flex: 1;
    transition: height 0.5s ease;
  }
  .kanban__column:not(:first-of-type) {
    margin-left: 15px;
  }
  .kanban__column--no-border {
    border: none;
  }
  .kanban__content {
    position: relative;
    height: 100%;
  }
  .kanban__skeleton {
    display: flex;
    width: 100%;
    height: 100%;
    padding-top: 15px;
    padding-right: 15px;
    background-color: #f8f8f8;
    border-radius: 4px;
    overflow: auto;
  }
  .kanban__skeleton-container {
    width: 100%;
  }
  .kanban__skeleton-column {
    flex: 1;
    height: calc(100% - 30px);
    padding: 10px;
    margin-left: 15px;
    background-color: #e9e9e9;
    border-radius: 6px;
  }
  .kanban__skeleton-card {
    position: relative;
    height: 100px;
    padding: 15px;
    margin-top: 16px;
    background-color: #f5f5f5;
    border-radius: 6px;
  }
  .kanban__skeleton-card--one {
    position: absolute;
    top: 8px;
  }
  .kanban__skeleton-card--two {
    position: absolute;
    top: 8px;
    right: 10px;
  }
  .kanban__skeleton-card--three {
    position: absolute;
    top: 30px;
  }
  .kanban__skeleton-card--four {
    position: absolute;
    bottom: 2rem;
  }
  .kanban__skeleton-card--five {
    position: absolute;
    left: 1rem;
    bottom: 0.5rem;
  }
  .item,
  .draggable-item,
  .draggable-item-horizontal {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 8px;
    cursor: pointer;
    user-select: none;
    background-color: white;
    border: 1px solid transparent;
    border-left: 5px solid #e0e0e0;
    border-radius: 4px;
    transition: border-color 0.2s linear;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1), 0 0 1px rgba(0, 0, 0, 0.1);
  }
  .item .card {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .item .card small {
    color: #838383;
  }
  .item.open {
    border-left: 5px solid #f3c1c1;
  }
  .item.executing {
    border-left: 5px solid #c4ebaf;
  }
  .item.finished {
    border-left: 5px solid #b3d5e9;
  }
  .item.paralised {
    border-left: 5px solid #dc3545;
  }
  .item.review {
    border-left: 5px solid #e0e0e0;
  }
  .badge {
    margin: 1rem 0;
    cursor: pointer;
  }
  .label {
    color: #838383;
  }
  .kanban-action {
    display: flex;
    justify-content: space-between;
  }
  .kanban-button {
    max-width: 130px;
    pointer-events: none;
    border: none;
    color: #f7f7f7;
    padding: 1px 20px;
    text-align: center;
    text-decoration: none;
    border-radius: 100px;
  }
  .kanban-button.open {
    background-color: #f3c1c1;
  }
  .kanban-button.executing {
    background-color: #c4ebaf;
  }
  .kanban-button.finished {
    background-color: #b3d5e9;
  }
  .kanban-button.paralised {
    background-color: #dc3545;
  }
  .kanban-button.review {
    background-color: #e0e0e0;
  }
  /* end of kanban  */
</style>
