<template>
  <div v-if="isShowSliders">
    <!-- swiper1 -->
    <swiper
      ref="swiperTop"
      class="swiper-gallery gallery-top"
      :options="swiperOptions"
    >
      <swiper-slide
        v-for="(data,index) in files"
        :key="index"
      >
        <div class="ys-swiper-slide d-flex align-items-center justify-content-center">
          <b-img
            v-if="data.mime.includes('image')"
            :src="data.url"
            fluid
          />
          <div
            v-if="data.mime.includes('video')"
            class="w-75"
          >
            <b-embed
              type="video"
              aspect="16by9"
              :src="data.url"
              allowfullscreen
              controls
            ></b-embed>
          </div>
        </div>
        
      </swiper-slide>
      
      <div
        slot="button-next"
        class="swiper-button-next"
      />
      <div
        slot="button-prev"
        class="swiper-button-prev"
      />
    </swiper>
    
    <!-- swiper2 Thumbs -->
    <swiper
      ref="swiperThumbs"
      class="swiper gallery-thumbs mt-2"
      :options="swiperOptionThumbs"
    >
      <swiper-slide
        v-for="(data,index) in files"
        :key="index"
        class="slk-swiper-slide"
      >
        <b-img
          v-if="data.mime.includes('image')"
          :src="data.url"
          rounded
          width="150"
          height="150"
        />
        <div
          v-if="data.mime.includes('video')"
          class="slk-swiper__video"
        >
          <b-embed
            type="video"
            aspect="16by9"
            :src="data.url"
          ></b-embed>
          <feather-icon icon="PlayIcon" class="ys-task-attach__play-icon text-primary"></feather-icon>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg, BEmbed } from 'bootstrap-vue'
import 'swiper/css/swiper.css'

export default {
    name: "SwiperThumb",
    props: {
    	files: [Array],
      initSlide: [Number]
    },
		components: {
				Swiper,
				SwiperSlide,
				BImg,
        BEmbed
		},
		data() {
				return {
						isShowSliders: false,
						swiperOptions: null,
						swiperOptionThumbs: {
								loop: false,
								loopedSlides: 6,
								spaceBetween: 10,
								centeredSlides: true,
								slidesPerView: 6,
								touchRatio: 0.2,
								slideToClickedSlide: true,
						},
				}
		},
		mounted() {
						setTimeout(() => {
                this.swiperOptions = {
                    loop: false,
                    loopedSlides: this.files.length,
                    initialSlide: this.initSlide,
                    spaceBetween: 10,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                };
                this.swiperOptionThumbs.loopedSlides = this.files.length;
                this.swiperOptionThumbs.initialSlide = this.initSlide;
                this.isShowSliders = true;
														this.$nextTick(() => {
																const swiperTop = this.$refs.swiperTop.$swiper;
																const swiperThumbs = this.$refs.swiperThumbs.$swiper;
																console.log(swiperTop);
																swiperTop.controller.control = swiperThumbs;
																swiperThumbs.controller.control = swiperTop;
														})
            }, 100);
			
		},
}
</script>

<style scoped>

</style>
