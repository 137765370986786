<template>
  <div>
    <div class="d-flex align-items-start">
      <feather-icon
        v-b-tooltip.hover.v-secondary
        title="Copy task name"
        size="20"
        icon="LinkIcon"
        class="mt-1 cursor-pointer d-block slk-copytaskname-icon"
        @click="copyLink"
      />
      <lk-input label="" :isLabel="true" :hover="false" class="ml-1">
        <template v-slot:field>
          <b-form-input
            placeholder="Название задачи"
            v-model="task.name"
            class="slk-task__name-input"
          />
        </template>
        <template v-slot:value>
          <div class="slk-task__name">{{ task.name }}</div>
        </template>
      </lk-input>
    </div>

    <div class="d-flex mb-1 mt-1">
      <b-dropdown
        id="dropdown-7"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        :text="stepName"
        :style="{ background: statusColor }"
        class="mr-1"
        variant="custom"
        v-if="dictionaries"
      >
        <b-dropdown-item
          :key="step.id"
          v-for="step in dictionaries.workflow_steps"
          @click="setStep(step)"
          >{{ step.name }}</b-dropdown-item
        >
      </b-dropdown>

      <label for="inputfile" class="slk-input-file">
        <div class="btn btn-icon mr-1 btn-outline-secondary">
          <feather-icon icon="PaperclipIcon" />
        </div>
        <input type="file" id="inputfile" @change="loadFile($event)" />
      </label>
    </div>

    <lk-input label="" :isLabel="true" :hover="false">
      <template v-slot:field>
        <quill-text-editor v-model="task.description" :task="task"></quill-text-editor>
      </template>
      <template v-slot:value>
        <div class="ql-snow">
          <div
            class="slk-task-desc ql-show"
            v-html="task.description.length ? description : 'Добавить описание...'"
          ></div>
        </div>
      </template>
    </lk-input>

    <task-content-epic
      v-if="task.type.name == 'epic'"
      :dictionaries="dictionaries"
      :epic="task"
      :lengthName="lengthName"
    ></task-content-epic>

    <task-attachments
      v-if="task.attachments.length > 0 || isLoadAttachment"
      :attachments="task.attachments"
      :isLoadAttachment="isLoadAttachment"
    ></task-attachments>

    <task-activity :task="task"></task-activity>
  </div>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BDropdown,
  BButton,
  BDropdownItem,
  VBTooltip,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import TaskActivity from './TaskActivity';
import { updateTask, dataTask } from '@/mixins/task';
import LkInput from '../components/LkInput';
import TaskContentEpic from './TaskContentEpic';
import { taskApi } from '../../api';
import { mapState } from 'vuex';
import QuillTextEditor from '../components/QuillTextEditor';
import TaskAttachments from './TaskAttachments';

export default {
  name: 'TaskContent',
  props: {
    task: [Object],
    dictionaries: [Object],
    comments: [Array],
    lengthName: {
      type: [Boolean, String],
      default: false,
    },
  },
  components: {
    BFormInput,
    BFormGroup,
    BDropdown,
    BButton,
    TaskActivity,
    BDropdownItem,
    LkInput,
    TaskContentEpic,
    QuillTextEditor,
    TaskAttachments,
  },
  mixins: [updateTask, dataTask],
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  computed: {
    ...mapState('project', ['currentProject']),
    description() {
      return this.task.description.replaceAll(/<p><\/p>/gi, '<br>');
    },
  },
  data: () => ({
    stepName: '',
    statusColor: '#82868b',
    isLoadAttachment: false,
  }),
  methods: {
    loadFile(e) {
      const files = e.target.files;
      if (files.length > 0) {
        this.isLoadAttachment = true;
        const formData = new FormData();
        formData.append('filename', files[0]);
        taskApi
          .uploadFile(this.currentProject, this.task.number, formData)
          .then((res) => {
            taskApi.task(this.currentProject, this.task.number).then((res) => {
              this.isLoadAttachment = false;
              this.$store.dispatch('project/updateActiveTask', res.data.task);
            });
          })
          .catch((error) => {
            this.$bvToast.toast(`Ошибка загрузки данных.`, {
              title: this.$t('Data Update'),
              variant: 'danger',
              solid: true,
            });
            this.isLoadAttachment = false;
          });
      }
    },
    copyLink() {
      navigator.clipboard.writeText(this.task.name).then(() => {
        this.$bvToast.toast(this.$t('Copy name'), {
          title: this.$t('Copy'),
          variant: 'success',
          solid: true,
        });
      });
    },
    setStep(step) {
      this.$parent.taskWorkflowStep = step;
      this.stepName = step.name;
      this.statusColor = step.color;
      this.updateTask(
        this.task.number,
        {
          workflow_step_id: step.hasOwnProperty('id') ? step.id : null,
        },
        false,
      );
    },
    getColor(status) {
      const curStatus = this.dictionaries.workflow_steps.filter((item) => item.id == status.id);
      return curStatus.length > 0 ? curStatus[0]['color'] : '#82868b';
    },
  },
  watch: {
    dictionaries: function (val) {
      if (val) {
        this.statusColor = this.getColor(this.task.status);
      }
    },
  },
  mounted() {
    console.log('taskComment:', this.task.description);
    this.stepName = this.task.status ? this.task.status.name : 'Не задано';
    if (this.dictionaries) this.statusColor = this.getColor(this.task.status);
  },
};
</script>
