<template>
  <div>
    <lk-input label="Исходная оценка" :cols="4" :hover="false">
      <template v-slot:field>
        <time-input v-model="task.estimate" @callback="saveTask"></time-input>
      </template>
      <template v-slot:value>
        <b-badge variant="light-dark">{{ toFormatFromSec1(task.estimate, false) }}</b-badge>
  
      </template>
    </lk-input>
  
    <b-form-group
      label="Учет времени"
      label-for="time"
      label-cols-md="4"
      class="mt-1 cursor-pointer"
      v-b-modal.modal-time-tracking
    >
      <b-progress
        v-model="progressSpent"
        :max="progressMax"
        :class="progressClass"
      />
      <small>{{ toFormatFromSec1(timeSpent, false) }}</small><br>
      <small>зарегистрировано</small>
    </b-form-group>
    <time-tracker :task="task" @updateSpent="updateSpent"></time-tracker>
    <modal-time-tracking :task="task"></modal-time-tracking>
  </div>
</template>

<script>
import {
    BFormGroup, BFormInput, BButton, BProgress, BBadge
} from 'bootstrap-vue'
import TimeTracker from "../time-tracker/TimeTracker";
import ModalTimeTracking from "../time-tracker/ModalTimeTracking";
import { toFormatFromSec1 } from "@/helpers"
import TimeInput from "../components/TimeInput";
import LkInput from "../components/LkInput";
import { updateTask, dataTask } from "@/mixins/task";
import { taskApi } from "../../api";
import { mapState } from "vuex";

export default {
  name: "TaskTime",
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BProgress,
    TimeTracker,
    ModalTimeTracking,
    TimeInput,
    LkInput,
    BBadge
  },
  props: {
      task: [Object]
  },
  mixins: [updateTask, dataTask],
  data: () => ({
    progress: 100,
    timeSpent: null,
    progressSpent: null,
    progressMax: null,
    progressClass: 'studio-progress-bar',
    toFormatFromSec1,
  }),
  computed: {
      ...mapState("project", ["currentProject"])
  },
  methods: {
		  updateSpent(newSpent) {
          this.timeSpent = newSpent;
      },
		  saveTask() {
          const data = this.getTaskData(this.task);
          this.updateTask(this.task.number, data, false);
      }
  },
  mounted() {
  		if(this.task.estimate >= this.task.time_tracker.spent || this.task.estimate == 0) {
          this.progressSpent = this.task.time_tracker.spent;
          this.progressMax = this.task.estimate;
          this.progressClass = this.task.estimate == 0 ? 'yellow-progress-bar' : 'studio-progress-bar';
      } else {
          this.progressSpent = this.task.estimate;
          this.progressMax = this.task.time_tracker.spent;
          this.progressClass ='higher-progress-bar';
      }
    
  		this.timeSpent = this.task.time_tracker.spent
  }
}
</script>

<style scoped>

</style>
