<template>
  <b-modal
    id="modal-stop-sprint"
    no-close-on-backdrop
    content-class="shadow"
    title="Завершить спринт"
  >
    <div v-if="openSprint && openSprint.need_transfer">
      <b-alert variant="primary" show class="mb-1">
        <div class="alert-body">
          <span><strong>В спринте есть незавершенные задачи. Выберите спринт для переноса. Если спринт не выбран задачи будут отправлены в бэклог.</strong></span>
        </div>
      </b-alert>
      <b-list-group>
        <template v-for="sprint in sprints">
          <b-list-group-item
            v-if="!sprint.started_at.length"
            @click="selectSprint(sprint)"
            :active="selectedSprint == sprint.uuid">{{ sprint.name }}</b-list-group-item>
        </template>
      </b-list-group>
    </div>
    
    <div v-else>
      Вы уверены, что хотите завершить спринт?
    </div>
    
    <template #modal-footer>
      <button type="button" class="btn btn-secondary" @click="$bvModal.hide('modal-stop-sprint');">Отмена</button>
      <button
        type="button"
        class="btn btn-primary"
        @click="stopSprint"
        :disabled="submitDisable"
      >OK</button>
    </template>
  
  </b-modal>
</template>

<script>
		import {
				BModal, BListGroup, BListGroupItem, BAlert
		} from 'bootstrap-vue'
		import { projectApi } from "@/api";
		import { required } from '@validations'
		import { mapState, mapActions } from 'vuex';
		import { getErrors } from "@/helpers/errors";
		
		export default {
				name: "BacklogStopSprint",
				components: {
						BModal,
            BListGroup,
            BListGroupItem,
            BAlert
				},
				data: () => ({
            startedAt: null,
            expiredAt : null,
            submitDisable: false,
            selectedSprint: null,
						required,
				}),
        computed: {
            ...mapState("project", ["currentProject", "openSprint", "activeSprintStatus", "sprints"]),
        },
				methods: {
            ...mapActions("project", ["getSprints", "getBacklogTasks"]),
						selectSprint(sprint) {
								if(sprint.uuid == this.selectedSprint) {
                  this.selectedSprint = null;
                } else {
                  this.selectedSprint = sprint.uuid;
                }
								
            },
            stopSprint() {
								let data = this.selectedSprint ? { next_sprint_uuid: this.selectedSprint } : {};
								this.submitDisable = true;
		            projectApi.stopSprint(this.currentProject, data).then(() => {
                    this.successToast();
                    this.$bvModal.hide('modal-stop-sprint');
                    this.getSprints();
                    this.getBacklogTasks();
                }).catch(() => {
						            if(error.response) {
								            const errors = getErrors(error.response.data.errors);
								            if(errors.general) {
										            this.errorToast(errors);
								            }
						            }
                }).then(() => {
                    this.submitDisable = false;
                })
						},
						hideModal() {
								this.$store.dispatch("project/updateOpenSprint", null);
						},
						successToast() {
								this.$bvToast.toast("Спринт успешно завершен", {
										title: this.$t("Stop sprint"),
										variant: "success",
										solid: true,
								});
						},
						errorToast(errors) {
								this.$bvToast.toast(`Ошибка при завершений спринта. ERROR: ${errors.general[0].status} ${errors.general[0].message}`, {
										title: this.$t("Stop sprint"),
										variant: "danger",
										solid: true,
								});
						}
				},
		}
</script>

<style lang="scss" scoped>
  .list-group-item {
    cursor: pointer;
  }
</style>

