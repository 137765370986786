<template>
  <b-modal
    id="modal-time-tracking"
    title="Учет времени"
    ok-title="Сохранить"
    cancel-title="Отменить"
    cancel-variant="outline-secondary"
    ok-disabled
    size="sm"
    @show="showModal"
  >
    <div class="mt-1 mb-1" v-if="taskTracker">
      <b-progress
        v-model="task.time_tracker.spent"
        max="100"
        class="studio-progress-bar"
      />
      <small>{{ toFormatFromSec1(taskTracker.spent) }} зарегистрировано</small><br>
    </div>
    <div class="mb-2" v-if="taskTracker && taskTracker.history">
      <div v-for="tracker in groupHistory">
        <dl class="row mb-0" v-b-toggle="'collapsetrack-'+ tracker.id">
          <dt class="col-sm-8">{{ tracker.assignee_name }}</dt>
          <dd class="col-sm-4">{{ toFormatFromSec1(tracker.total_spent) }}
            <feather-icon icon="ChevronDownIcon" class="tracker-list-icon" />
          </dd>
        </dl>
  
        <b-collapse
          :visible="false"
          :id="'collapsetrack-'+ tracker.id"
          class="mb-1"
          >
          <div class="row tracker-history">
            <div class="col-sm-4">Старт</div>
            <div class="col-sm-4">Стоп</div>
            <div class="col-sm-4">Время</div>
          </div>
          <div class="row tracker-history" v-for="history in tracker.history">
            <div class="col-sm-4">{{ history.started_at | formatDateWithoutYear }}</div>
            <div class="col-sm-4">{{ history.finished_at | formatDateWithoutYear}}</div>
            <div class="col-sm-4">{{ toFormatFromSec1(history.spent) }}</div>
          </div>
        </b-collapse>
      </div>
      
    </div>
    
    <div>
      <small><b>The original estimate for this issue was </b> </small>
      <b-badge variant="light-dark">{{ toFormatFromSec1(task.estimate) }}</b-badge>.
    </div>
    
    
    <b-form class="mt-2">
      <b-row>
        <b-col md="6">
          <b-form-group
            label="Затраченное время"
            label-for="spent-time"
          >
            <b-form-input
              id="spent-time"
              placeholder="2w 4d 6h 45m"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Осталось времени"
            label-for="left-time"
          >
            <b-form-input
              id="left-time"
              placeholder="0m"
              value="0m"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </b-modal>
</template>

<script>
import { BFormGroup, BFormInput, BButton,  BProgress, BModal, VBModal, BBadge, BForm, BRow, BCol, BCollapse, VBToggle } from 'bootstrap-vue'
import { toFormatFromSec1 } from "@/helpers"
import { taskApi } from "@/api";
import { mapState } from "vuex";

export default {
  name: "ModalTimeTracking",
  components: {
    BFormGroup,
    BFormInput,
    BButton,
    BProgress,
    BModal,
    BBadge,
    BForm,
    BRow,
    BCol,
    BCollapse
  },
  directives: {
    'v-b-modal': VBModal,
    'b-toggle': VBToggle,
  },
  props: {
      task: [Object]
  },
  data: () => ({
    progress: 100,
    taskTracker: null,
    toFormatFromSec1,
  }),
  computed: {
      ...mapState("project", ["currentProject"]),
      groupHistory() {
		      let out = {};
		      if(this.task && this.task.time_tracker) {
              this.task.time_tracker.history.map((item) => {
                  if(out.hasOwnProperty(item.assignee_name)) {
                      out[item.assignee_name]["total_spent"] += item.spent;
                      let history = out[item.assignee_name]["history"];
                      history.push(item)
                      out[item.assignee_name]["history"] = history;
                  } else {
                      out[item.assignee_name] = {
                          "id": item.assignee_name.replaceAll(' ', '_'),
                          "assignee_name": item.assignee_name,
                          "total_spent" : item.spent,
                          "history": [item]
                      }
                  }
              });
          }
		
		      return out;
      },
  },
  methods: {
		  showModal() {
          taskApi.getTimeTracker(this.currentProject, parseInt(this.task.number)).then((res) => {
              this.taskTracker = res.data.time_tracker;
          })
      },
  },
}
</script>

<style scoped>
  .not-collapsed .tracker-list-icon {
    transform: rotate(180deg);
  }
  .row.tracker-history {
    font-size: 12px;
  }
</style>
