<template>
  <div>
    <div class="slk-loader" v-if="isPending">
      <b-spinner label="Loading..." />
    </div>
    <div class="board" v-if="filterDataSprint && projectConfig">
      <board-nav
        v-if="board"
        :sprint="board.sprint"
        :members="board.members"
        :configFiltres="projectConfig.filters"
        @filterSprint="filterSprint"
      ></board-nav>

      <div class="kanban mt-2 kanban-status-list" v-if="dictionaries">
        <div class="kanban__column" v-for="column in workflow">
          <div class="kanban__title">
            <span>{{ column.name }}</span> <span>({{ column.taskCount }})</span>
          </div>
        </div>
      </div>
      <div class="ref0" ref="ref0"></div>
      <template v-for="(epic, index) in filterDataSprint.epics">
        <div v-if="countTaskEpic[epic.number] > 0">
          <div class="board-epic__title mt-2" v-b-toggle="'collapse-' + epic.number">
            <feather-icon icon="ChevronRightIcon" class="board-epic__title-icon" />
            {{ epic.name }}
            <span class="text-muted">{{ countTask(epic) }} задача(и)</span>
          </div>
          <b-collapse :visible="true" :id="'collapse-' + epic.number" class="mt-1">
            <div class="kanban-cards">
              <Kanban
                :columns.sync="epic.statuses"
                :groupname="'epic-' + epic.number"
                col-min-width="100"
                count-text="items here"
              >
                <template v-slot:card="{ item }">
                  <div class="card" @click="openTask(item)">
                    <div class="kanban-item">
                      <b-avatar
                        v-if="item.assignee"
                        v-b-tooltip.hover
                        :title="item.assignee.firstname + ' ' + item.assignee.lastname"
                        :src="item.assignee.avatar"
                      />
                      <div class="mt-1">
                        <h6 class="slk-card-title">{{ item.name }}</h6>
                        <b-badge
                          variant="light-success"
                          @click.stop="openTask(epic)"
                          class="bagde-link"
                          >{{ epic.name }}</b-badge
                        >
                      </div>
                      <div class="d-flex mt-1 align-items-center flex-wrap">
                        <span class="mr-1">
                          <feather-icon
                            :icon="iconTask(item.type.name)"
                            :class="colorTask(item.type.name)"
                          />
                          <span :class="colorTask(item.type.name)">&nbsp;{{ item.type.name }}</span>
                        </span>
                        <span>{{ item.priority.icon }} {{ item.priority.name }}</span>
                      </div>
                      <template v-if="item.labels && item.labels.length > 0">
                        <b-badge
                          :style="{ background: label.color }"
                          class="mt-1"
                          variant="primary"
                          v-for="label in item.labels"
                          :key="label.id"
                          >{{ label.name }}</b-badge
                        >
                      </template>
                      <div class="d-flex align-items-start flex-wrap">
                        <div class="text-muted mt-1 mr-1">{{ item.key }}</div>
                        <b-badge class="mr-1 mt-1" variant="light-dark">{{
                          diffTime(item)
                        }}</b-badge>

                        <b-badge class="mt-1" variant="light-warning">{{
                          toFormatFromSec1(item.estimate, false)
                        }}</b-badge>
                      </div>
                    </div>
                  </div>
                </template>
              </Kanban>
            </div>
          </b-collapse>
        </div>
      </template>

      <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        right
        backdrop
        shadow
        class="sidebar-task"
        no-header
        @hidden="hidden"
      >
        <template #default="{ hide }">
          <div class="ys-task__close" @click="hide">
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="18" />
          </div>
          <task-aside v-if="activeTaskData" :task="activeTaskData"></task-aside>
        </template>
      </b-sidebar>

      <backlog-stop-sprint></backlog-stop-sprint>
    </div>
  </div>
</template>

<script>
import Kanban from './Kanban.vue';
import { BSpinner, BBadge, BCollapse, VBToggle, BSidebar, BAvatar, VBTooltip } from 'bootstrap-vue';
import { colorTask, iconTask, toFormatFromSec1 } from '@/helpers';
import BoardNav from './BoardNav';
import TaskAside from '../task/TaskAside';
import { projectApi, taskApi } from '@/api';
import { mapState, mapActions } from 'vuex';
import BacklogStopSprint from '../backlog/BacklogStopSprint';
import { getErrors } from '@/helpers/errors';

export default {
  name: 'Board',
  components: {
    BacklogStopSprint,
    Kanban,
    BBadge,
    BCollapse,
    BoardNav,
    BSidebar,
    TaskAside,
    BAvatar,
    BSpinner,
  },
  computed: {
    ...mapState('project', ['currentProject', 'board', 'isPending', 'activeTask']),
    notMobile() {
      return window.innerWidth >= 1025;
    },
    countTaskEpic() {
      // Возвращем количество задач в эпике
      let epicTask = {};
      this.filterDataSprint.epics.map((item) => {
        let countTask = item.statuses.reduce(
          (previousValue, currentValue) => previousValue + currentValue.tasks.length,
          0,
        );
        epicTask[item.number] = countTask;
      });
      return epicTask;
    },
    workflow() {
      let out = [];
      this.dictionaries.workflow_steps.map((item) => {
        let step = item;
        let taskCount = 0;
        this.filterDataSprint.epics.map((epic) => {
          epic.statuses.map((status) => {
            if (status.id == item.id) taskCount += status.tasks.length;
          });
        });
        step.taskCount = taskCount;
        out.push(step);
      });
      return out;
    },
  },
  directives: {
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      colorTask,
      iconTask,
      activeTaskData: null,
      filterDataSprint: null,
      dictionaries: null,
      filterAssigneeList: [],
      filterCustomList: [],
      filterSearch: '',
      projectConfig: null,
      toFormatFromSec1,
    };
  },
  watch: {
    board: function (val) {
      this.filterDataSprint = val;
      if (this.filterSearch.length > 0 || this.filterAssigneeList.length > 0)
        this.filterSprint(this.filterAssigneeList, this.filterSearch, this.filterCustomList);
    },
    currentProject: function () {
      this.loadData();
    },
    activeTask: function (val) {
      this.activeTaskData = val;
    },
  },
  methods: {
    ...mapActions('project', ['getBoard']),
    diffTime(task) {
      let diff = task.estimate - task.time_spent;
      if (diff == 0) return diff + 's';
      if (diff > 0) {
        return this.toFormatFromSec1(task.time_spent, false);
      } else {
        return '-' + this.toFormatFromSec1(Math.abs(diff));
      }
    },
    hidden() {
      this.$store.dispatch('project/updateActiveTask', null);
    },
    openTask(task) {
      taskApi.task(this.currentProject, task.number).then((res) => {
        this.$store.dispatch('project/updateActiveTask', res.data.task);
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right');
      });
    },
    countTask(epic) {
      let tasks = 0;
      for (let i = 0; i < epic.statuses.length; i++) {
        tasks += epic.statuses[i].tasks.length;
      }
      return tasks;
    },

    filterSprint(assignee, search, customFilters) {
      this.filterCustomList = customFilters;
      this.filterAssigneeList = assignee;
      this.filterSearch = search;
      if (assignee.length > 0 || search.length > 0 || customFilters.length > 0) {
        let sprint = JSON.parse(JSON.stringify(this.board.epics));
        let filterTask = [];
        sprint.map((item) => {
          item.statuses.map((status) => {
            status.tasks = status.tasks.filter((task) => {
              let issueAssignee =
                assignee.length > 0
                  ? (task.assignee && assignee.includes(task.assignee.id)) ||
                    assignee.includes(task.assignee)
                  : true;
              let issueSearch =
                search.length > 0
                  ? task.key.toLowerCase().includes(search.toLowerCase()) ||
                    task.name.toLowerCase().includes(search.toLowerCase())
                  : true;
              let issueFilters =
                customFilters.length > 0 ? this.matchFilters(task, customFilters) : true;
              return issueAssignee && issueSearch && issueFilters;
            });
            return status;
          });
          filterTask.push(item);
        });
        this.filterDataSprint = Object.assign({}, this.board, { epics: filterTask });
      } else {
        this.filterDataSprint.epics = this.board.epics;
      }
    },
    matchFilters(task, filters) {
      let res = false;

      filters.map((item) => {
        // Фильтр по условию AND
        let isOr = false;
        item.params.map((param) => {
          // Фильтр по условию AND
          let isdAnd = true;
          param.map((condition) => {
            // AND
            switch (condition.assert) {
              case '=':
                if (!_.some(condition.values, task[condition.field])) {
                  isdAnd = false;
                }
                break;
              case '!=':
                if (_.some(condition.values, task[condition.field])) {
                  isdAnd = false;
                }
                break;
              case '>':
                //
                break;
              case '<':
                //
                break;
            }
          });
          if (isdAnd) isOr = true;
        });
        if (isOr) res = true;
      });
      return res;
    },
    loadData() {
      this.$store.dispatch('project/updatePending', true);
      this.getBoard()
        .catch((error) => {
          if (error.response) {
            if (this.$route.query.from)
              this.$router.push({
                name: 'projects-backlog',
                params: { project: this.currentProject },
              });
            const errors = getErrors(error.response.data.errors);
            if (errors.general) {
              this.$bvToast.toast(
                `Ошибка при загрузке данных. ERROR: ${errors.general[0].status} ${errors.general[0].message}`,
                {
                  title: this.$t('Data Load'),
                  variant: 'danger',
                  solid: true,
                },
              );
            }
          }
        })
        .then(() => this.$store.dispatch('project/updatePending', false));
      const getDictonaries = projectApi.getDictionaries(this.currentProject);

      Promise.all([getDictonaries]).then((results) => {
        this.dictionaries = results[0].data.project_dictionaries;
      });

      projectApi.getConfig(this.currentProject).then((res) => {
        this.projectConfig = res.data.project_config;
        // let userData = JSON.parse(localStorage.getItem("userData"));
        const projectPermissions = Object.values(res.data.project_config.permissions);

        const abilityRules = this.$ability.rules;
        const resPermissions = [];
        projectPermissions.forEach(({ available, context }) => {
          const sameRule = abilityRules.find(({ subject }) => context == subject);
          if (sameRule) {
            const resAvailible = _.union(sameRule.action, available);
            resPermissions.push({ action: resAvailible, subject: sameRule.subject });
          } else {
            resPermissions.push({ action: available, subject: context });
          }
        });
        const newAbilityRules = _.unionBy(resPermissions, abilityRules, 'subject');
        // console.log('resPermissions:', resPermissions);
        // console.log('ability_rules:', newAbilityRules);

        this.$ability.update(newAbilityRules); 
      });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.board {
  padding-bottom: 30px;
}
.board-epic__title .board-epic__title-icon {
  transition: all 0.4s;
}
.board-epic__title.not-collapsed .board-epic__title-icon {
  transform: rotate(90deg);
}
.kanban-item {
  padding: 1rem;
  cursor: pointer;
}
.card {
  margin-bottom: 1rem;
}
.kanban {
  display: flex;
  justify-content: stretch;
  width: 100%;
  height: 100%;
  user-select: none;
}

.kanban-status-list {
  position: sticky;
  top: 80px;
  z-index: 10;
  background: #f8f8f8;
  padding-bottom: 10px;
  padding-top: 10px;
}
.kanban__column {
  flex: 1;
  height: auto;
  transition: height 0.5s ease;
}
.kanban__column:not(:first-of-type) {
  margin-left: 15px;
}
.kanban__title {
  color: #5e5873;
  font-size: 0.9rem;
  padding: 0.5rem;
  font-weight: 500;
  width: 100%;
  background: #fff;
  text-transform: uppercase;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
</style>
