<template>
  <div class="slk-task-history">
    <div class="d-flex justify-content-start align-items-center mb-1 mt-2" v-for="item in history">
      <b-avatar
        :src="item.author.avatar"
        size="30"
        class="mr-1"
      />
      <div class="profile-user-info">
        <h6 class="mb-0">
          <b>{{ item.author.firstname }} {{ item.author.lastname }}</b> {{ item.action }}
          <template v-if="item.before">
            <br>
            <span ><b :style="{color:item.color}">{{ item.before }}</b>
               <feather-icon
                 icon="ArrowRightIcon"
                 size="12"
               />
            <b :style="{color:item.color}">{{ item.after }}</b></span>
          </template>
          
          <br>
          <small class="text-muted">{{ item.created_at | formatDateDefaultWithTime }}</small>
        </h6>
      </div>
    </div>
  </div>

</template>

<script>
import { BAvatar } from 'bootstrap-vue'
import { mapState } from "vuex";
import { taskApi } from "@/api";

export default {
    name: "TaskHistory",
		components: {
        BAvatar
		},
    props: {
        task: [Object],
    },
		computed: {
				...mapState("project", ["currentProject"]),
		},
		data: () => ({
				history: null,
		}),
    methods: {
        loadActivity() {
            taskApi.getHistory(
                this.currentProject,
                this.task.number
            ).then((res) => {
            		this.history = res.data.task_history;
            });
        },
    },
    mounted() {
		    this.loadActivity();
    }
}
</script>

<style scoped>

</style>
