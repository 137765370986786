<template>
  <li
    class="d-flex ys-tasks__item align-items-center draggable-task-handle"
    :data-task="task.number"
  >
    <feather-icon v-if="drag" icon="MoreVerticalIcon" class="d-inline" />
    <div
      @click="openTask"
      class="ys-tasks__wrapper d-flex ml-1 align-items-center justify-content-between ys-tasks__main"
    >
      <div class="align-items-center ys-tasks__left-container">
        <feather-icon :icon="iconTask(task.type.name)" :class="colorTask(task.type.name)" />
        <div class="ml-1 ys-tasks__title">{{ sliceName }}</div>
        <div class="ys-tasks__bage">
          <b-badge class="ml-0.3" :style="{ background: getColor(task.status) }">{{
            task.status.name
          }}</b-badge>
        </div>
        <div class="ys-tasks__bage">
          <template v-if="task.labels && task.labels.length > 0 && isLabel">
            <b-badge
              class="ml-0.3"
              :style="{ background: label.color }"
              v-for="label in task.labels"
              :key="label.id"
              >{{ label.name }}</b-badge
            >
          </template>
        </div>
      </div>

      <div class="ys-tasks__right-container">
        <div class="ys-tasks__base-item ys-tasks__epic">
          <small v-if="task.epic" class="ml-0">{{ task.epic.name }}</small>
        </div>
        <div class="ys-tasks__base-item">
          <b-avatar
            v-if="task.assignee"
            v-b-tooltip.hover
            :title="task.assignee.firstname + ' ' + task.assignee.lastname"
            :src="task.assignee.avatar"
          />
        </div>
        <span class="slk-story_point ys-tasks__base-item">
          <b-badge class="ml-1" v-if="task.story_point">{{ task.story_point }}</b-badge>
        </span>

        <div class="card-text ys-tasks__base-item">{{ task.key }}</div>
        <div class="ml-1">
          {{ task.priority.icon }}
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { BBadge, BAvatar, VBTooltip } from 'bootstrap-vue';
import { colorTask, iconTask } from '@/helpers';
import { mapState } from 'vuex';

export default {
  name: 'Task',
  components: {
    BBadge,
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    task: [Object],
    drag: {
      type: Boolean,
      default: true,
    },
    link: {
      type: [Boolean, String],
      default: false,
    },
    lengthName: {
      type: [Boolean, String],
      default: false,
    },
    isLabel: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      colorTask,
      iconTask,
    };
  },
  methods: {
    openTask() {
      if (this.link) window.open(this.link, '_blank');
    },
    getColor(status) {
      const curStatus = this.colorStatus.filter((item) => item.id == status.id);
      return curStatus.length > 0 ? curStatus[0]['color'] : '#82868b';
    },
  },
  computed: {
    ...mapState('project', ['colorStatus']),
    sliceName() {
      if (this.lengthName) {
        return this.task.name.length > this.lengthName
          ? this.task.name.slice(0, this.lengthName) + '...'
          : this.task.name;
      } else {
        return this.task.name;
      }
    },
  },
};
</script>

<style scoped>
.ys-tasks__main {
  display: flex;
  min-width: 49rem;
}

.ys-tasks__left-container {
  display: flex;
  /* background-color: pink; */
  flex: 5 1 0;
}

.ys-tasks__right-container {
  display: flex;
  flex: 3 1 4rem;
  align-self: start;
}

.ys-tasks__title {
  flex: 8 1 8rem;
}

.ys-tasks__bage {
  flex: 0.6 1 4rem;
  display: flex;
  justify-content: center;
  align-self: start;
  margin-left: 1px;
}

.ys-tasks__base-item {
  flex: 1 1 0;
  margin-left: 2px;
  /* background-color: yellow; */
  /* display: flex; */
  justify-content: center;
}

.ys-tasks__epic {
  flex: 2 1 5rem;
  justify-content: start;
  word-wrap: break-word;
}
</style>
