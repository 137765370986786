<template>
  <b-input-group size="sm">
    <b-form-input :state="invalid" placeholder="Название..." v-model="taskName" @keydown="invalid=null"/>
    <b-input-group-append>
      <b-button variant="primary" class="btn-icon" @click="addTask" :disabled="submitDisable">
        <feather-icon icon="SaveIcon" />
      </b-button>
    </b-input-group-append>
    <b-form-invalid-feedback tooltip>
      Заполните имя
    </b-form-invalid-feedback>
  </b-input-group>
</template>

<script>
import { BFormInput, BInputGroup, BInputGroupAppend, BButton, BFormInvalidFeedback } from 'bootstrap-vue'
import { taskApi } from "@/api";
import { mapState, mapGetters, mapActions } from 'vuex';
import { getErrors } from "@/helpers/errors";

export default {
    name: "ShortTaskForm",
		components: {
				BFormInput, BInputGroup, BInputGroupAppend, BButton, BFormInvalidFeedback
		},
    props: {
		    epicNumber: [Number]
    },
		computed: {
				...mapState("project", ["currentProject"]),
        ...mapGetters("project", ["defaultPriority", "statusTaskId"]),
		},
		data: () => ({
				invalid: null,
				taskName: '',
				submitDisable: false,
		}),
    methods: {
		    ...mapActions("project", ["getBacklogTasks", "getSprints"]),
		    addTask() {
				    if(this.taskName.length > 0) {
						    this.submitDisable = true
						    let task = {
								    project_key: this.currentProject,
								    type_id: this.statusTaskId,
								    name: this.taskName,
								    priority_id: this.defaultPriority['id'],
								    epic_number: this.epicNumber
						    };
						    taskApi.create(task).then(res => {
								    this.makeSuccessToast(res.data.number);
								    this.taskName = '';
								    this.$emit('callback');
								    this.submitDisable = false;
						    }).catch(error => {
								    if(error.response) {
										    const errors = getErrors(error.response.data.errors);
										    if(errors.general) {
												    this.makeErrorToast(errors.general[0].status, errors.general[0].message);
										    }
								    }
								    this.submitDisable = false;
						    });
				    } else {
						    this.invalid = false
				    }
		    },
		    makeSuccessToast(key) {
				    const h = this.$createElement;
				    const href = '/'+ this.currentProject +'-'+ key;
				    const vNodesContent = h('div', { }, [
						    h('span', { }, 'Задача с ключом '),
						    h('a', { attrs: { href: href }, class: 'font-weight-bold' }, this.currentProject + '-' + key),
						    h('span', { }, ' успешно создана'),
				    ]);
				
				    this.$bvToast.toast([vNodesContent], {
						    title: this.$t("Сreating a task"),
						    variant: "success",
						    solid: true,
				    });
		    },
		    makeErrorToast(status, message) {
				    this.$bvToast.toast(`Ошибка при создании задачи. ERROR: ${status} ${message}`, {
						    title: this.$t("Сreating a task"),
						    variant: "danger",
						    solid: true,
				    });
		    },
    }
  
}
</script>

<style scoped>

</style>
