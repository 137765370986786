var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('lk-input',{attrs:{"label":_vm.label,"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"field",fn:function(){return [_c('v-select',{attrs:{"options":_vm.options,"placeholder":_vm.label,"label":"firstname","disabled":_vm.disabled},scopedSlots:_vm._u([{key:"selected-option",fn:function(ref){
var firstname = ref.firstname;
var lastname = ref.lastname;
var avatar = ref.avatar;
return [_c('div',{staticClass:"vSelect-studio-img"},[_c('div',{staticClass:"vSelect-avatar"},[_c('img',{attrs:{"src":avatar}})]),_c('span',[_vm._v(" "+_vm._s(firstname)+" "+_vm._s(lastname))])])]}},{key:"option",fn:function(ref){
var firstname = ref.firstname;
var lastname = ref.lastname;
var avatar = ref.avatar;
return [_c('div',{staticClass:"vSelect-studio-img"},[_c('div',{staticClass:"vSelect-avatar"},[_c('img',{attrs:{"src":avatar}})]),_c('span',[_vm._v(" "+_vm._s(firstname)+" "+_vm._s(lastname))])])]}}]),model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}})]},proxy:true},{key:"value",fn:function(){return [_c('div',{staticClass:"vSelect-studio-img"},[_c('div',{staticClass:"vSelect-avatar"},[(_vm.value)?_c('img',{attrs:{"src":_vm.value.avatar}}):_vm._e()]),(_vm.value)?_c('span',[_vm._v(" "+_vm._s(_vm.value.firstname)+" "+_vm._s(_vm.value.lastname))]):_c('span',[_vm._v("Не задано")])])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }