<template>
  <div class="slk-input-group">
    <b-form-group
      :label="label"
      :label-cols-md="cols"
      :label-sr-only="isLabel"
    >
      <div ref="toggleElem" v-click-outside="toggle" class="slk-input d-flex align-items-center">
        <div v-if="inputShow" class="w-100" ><slot name="field"></slot></div>
        
        <div :class="{'col-form-label cursor-pointer slk-input-block':true, 'form-input-hover':hover}"
             v-if="!inputShow"
             @click="inputShow=true">
          <slot name="value"></slot>
        </div>
      </div>
  
  
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup } from 'bootstrap-vue'
export default {
    name: "LlInput",
		components: {
				BFormGroup,
		},
		props: {
				label: [String],
        isLabel: {
		        type: Boolean,
		        default: false
        },
        cols: {
						type: Number,
            default: 12
        },
        hover: {
						type: Boolean,
            default: true
        }
		},
    data: () => ({
        inputShow: false,
    }),
  methods: {
		  toggle(e) {
    				this.inputShow = false;
        }
  }
}
</script>

<style scoped>
.slk-input-group[disabled] {
  pointer-events: none;
}
.slk-input-group[disabled] .slk-input {
  background: #4b4b4b1f;
}
</style>
