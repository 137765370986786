<template>
  <div class="ys-task__activity mt-2">
    <div class="ys-task__activity__head d-flex justify-content-between align-items-center mb-1">
      <h5><b>Активность</b></h5>
    </div>
   
    <b-tabs>
      <b-tab title="Все">
        <b-card-text></b-card-text>
      </b-tab>
      
      <b-tab
        active
        title="Комментарий"
      >
        <b-card-text>
          <task-comment :task="task"></task-comment>
        </b-card-text>
      </b-tab>
      <b-tab
        title="История"
      >
        <b-card-text>
          <task-history :task="task"></task-history>
        </b-card-text>
      </b-tab>
    </b-tabs>
    
  </div>

</template>

<script>
import { BTab, BTabs, BCardText } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import TaskComment from "./TaskComment";
import TaskHistory from "./TaskHistory";

export default {
    name: "TaskActivity",
		components: {
				BTabs,
				BTab,
        BCardText,
        TaskComment,
        TaskHistory
		},
    props: {
        task: [Object]
    },
}
</script>
