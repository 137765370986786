<template>
  <lk-input :label="label" :disabled="disabled">
    <template v-slot:field>
      <v-select
        v-model="model"
        :options="options"
        :placeholder="label"
        label="firstname"
        :disabled="disabled"
      >
        <template #selected-option="{ firstname, lastname, avatar }">
          <div class="vSelect-studio-img">
            <div class="vSelect-avatar"><img :src="avatar"></div>
            <span> {{ firstname }} {{ lastname }}</span>
          </div>
        </template>
        <template #option="{ firstname, lastname, avatar }">
          <div class="vSelect-studio-img">
            <div class="vSelect-avatar"><img :src="avatar"></div>
            <span> {{ firstname }} {{ lastname }}</span>
          </div>
        </template>
      </v-select>
    </template>
    <template v-slot:value>
      <div class="vSelect-studio-img" >
        <div class="vSelect-avatar"><img v-if="value" :src="value.avatar"></div>
        <span v-if="value">  {{ value.firstname }} {{ value.lastname }}</span>
        <span v-else>Не задано</span>
      </div>
    </template>
  </lk-input>
</template>

<script>
import LkInput from "../components/LkInput";
import vSelect from 'vue-select'

export default {
    name: "LkSelect",
		components: {
				LkInput,
        vSelect,
		},
		props: {
				value: [Object],
				options: [Object, Array],
        label: {
						type: String,
            default: 'Field'
        },
        disabled: {
						type: Boolean,
            default: false
        }
		},
		computed: {
				model: {
						get: function() {
								return this.value
						},
						set: function(value) {
								this.$emit('input', value)
						}
				}
		}
}
</script>

<style scoped>

</style>
