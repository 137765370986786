<template>
  <div class="mt-1">
    <div class="d-flex justify-content-between mb-1">
      <h6 class="mb-0">Задачи в этом эпике</h6>
      <div>
        <div class="cursor-pointer d-flex align-items-center" @click="isCreateTask=!isCreateTask"><feather-icon icon="PlusIcon" /></div>
      </div>
    </div>
    <div v-if="isCreateTask" class="mb-1">
      <short-task-form
        :epicNumber="epic.number"
        @callback="update"
      ></short-task-form>
    </div>
    <ul class="slk-task-epics">
      <task
        v-for="task in epic.child_tasks"
        :key="task.id"
        :task="task"
        :drag="false"
        :link="'/'+currentProject+'-'+ task.number"
        :lengthName="lengthName"
        :isLabel="false"
      ></task>
    </ul>
  </div>
</template>

<script>
import { BFormInput, BInputGroup, BInputGroupAppend, BButton, BFormInvalidFeedback } from 'bootstrap-vue'
import { taskApi, projectApi } from "@/api";
import { mapState, mapActions } from 'vuex';
import ShortTaskForm from "./ShortTaskForm";
import Task from "@/views/task/Task";

export default {
  name: "TaskContentEpic",
  components: {
		  BFormInput, BInputGroup, BInputGroupAppend, BButton, BFormInvalidFeedback, ShortTaskForm, Task
  },
  props: {
        dictionaries: [Object],
        epic: [Object],
        lengthName: {
            type: [Boolean, String],
            default: false,
        },
  },
  data: () => ({
      isCreateTask: false,
      invalid: null,
      taskName: '',
		  submitDisable: false,
  }),
  computed: {
      ...mapState("project", ["currentProject", "activeEpic"]),
  },
  methods: {
		  ...mapActions("project", ["getBacklogTasks", "getSprints"]),
		  updateActiveEpic(epics, activeEpic) {
				  const current = epics.filter(item => item.number === activeEpic.number );
				  if(current.length) this.$store.dispatch('project/updateActiveEpic', current[0]);
		  },
      update() {
		      this.isCreateTask = false;
		      taskApi.task(this.currentProject, this.epic.number).then((res) => {
				      this.$store.dispatch('project/updateActiveTask', res.data.task);
		      });
		      if(this.$route.name == "projects-backlog") {
				      this.getBacklogTasks();
				      this.getSprints();
              projectApi.getEpics(this.currentProject).then((res) => {
                  this.updateActiveEpic(res.data.backlog_epics, this.activeEpic)
              })
		      }
      }
  }
}
</script>

<style scoped>

</style>
