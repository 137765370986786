<template>
  <div class="d-flex position-relative pb-4">
    <div class="ys-task__body">
      <div class="ys-task__nav d-flex  align-items-center">
        <feather-icon :icon="iconTask(task.type.name)" :class="colorTask(task.type.name)" />
        <router-link
          class="ml-1"
          :to="{ name: 'projects-task', params: { project: currentProject, task: task.number }}">{{ task.key }}</router-link>
        <feather-icon icon="LinkIcon" class="text-muted ys-task__copylink" @click="copyLink" />
      </div>
      <br>
      <task-content
        :task="task"
        :dictionaries="dictionaries"
        lengthName="20"
      ></task-content>
      
    </div><!-- Task body -->
    
    <div class="ys-task__info">
      <task-info
        :task="task"
        :config="config"
        :dictionaries="dictionaries"
      ></task-info>
    </div>
    <div class="ys-task__action">
      <b-button variant="primary" @click="save">Сохранить</b-button>
    </div>
  </div>


</template>

<script>
import TaskInfo from "./TaskInfo";
import TaskContent from "./TaskContent";
import { colorTask, iconTask } from "@/helpers";
import { projectApi } from "@/api";
import { mapState } from "vuex";
import { BButton } from 'bootstrap-vue'
import { updateTask, dataTask } from "@/mixins/task";

export default {
    name: "TaskAside",
    props: {
        task: [Object],
    },
    components: {
        TaskInfo,
        TaskContent,
		    BButton
    },
		mixins: [updateTask, dataTask],
    data: function() {
        return {
		        colorTask,
		        iconTask,
            config: null,
            dictionaries: null,
        }
    },
    computed: {
        ...mapState("project", ["currentProject"])
    },
    methods: {
        save() {
		        const data = this.getTaskData(this.task);
		        this.updateTask(this.task.number, data, false);
        },
		    copyLink() {
              navigator.clipboard.writeText(window.location.origin + '/'+this.currentProject+'-'+ this.task.number)
              .then(() => {
		              this.$bvToast.toast(this.$t("Copy link"), {
				              title: this.$t("Copy"),
				              variant: "success",
				              solid: true,
		              });
              });
        }
    },
    mounted() {
		    const getConfig = projectApi.getConfig(this.currentProject);
		    const getDictionaries = projectApi.getDictionaries(this.currentProject);
		
		    Promise.all([getConfig, getDictionaries]).then((results) => {
				    this.config = results[0].data.project_config;
				    this.dictionaries = results[1].data.project_dictionaries;
		    });
    }
}
</script>
