<template>
  <div class="d-flex">
    <!-- Searchn Input -->
    <b-input-group
      class="input-group-merge ys-search-input mr-2"
      size="sm"
    >
      <b-form-input placeholder="Поиск... " v-model="search" />
      <b-input-group-append is-text>
        <feather-icon icon="SearchIcon" />
      </b-input-group-append>
    </b-input-group>
    
    <!-- Avatar Group -->
    <b-avatar-group size="32px" v-if="members">
      <b-avatar
        v-for="member in members"
        v-b-tooltip.hover
        :class="{'pull-up': true, 'active': activeAssigneeList.includes(member.id)}"
        :title="member.firstname + member.lastname"
        :src="member.avatar"
        :key="member.id"
        @click.native="searchAssignee(member)"
      />
    </b-avatar-group>
    
    <b-button
      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
      :variant="activeMyTask ? 'outline-primary' : 'outline-secondary'"
      pill
      size="sm"
      class="ml-2"
      @click="filterMyTask"
    >
      Только мои задачи
    </b-button>
  
  </div>

</template>

<script>
import {
    BAvatarGroup,
    BAvatar,
    VBTooltip,
    BButton,
    BPopover,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moment from "moment";
import { mapState } from "vuex";

export default {
    name: "NavFilters",
    components: {
        BAvatarGroup,
        BAvatar,
        BButton,
        BPopover,
        BDropdown,
        BDropdownItem,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    props: {
        members: [Array],
    },
    data: function() {
        return {
            search: "",
            activeMyTask: false,
            activeAssigneeList: [],
        }
    },
    computed: {
        ...mapState("user", ["userData"])
    },
    methods: {
        searchAssignee(member) {
            this.activeMyTask = false;
            if(this.activeAssigneeList.includes(member.id)) {
                this.activeAssigneeList.splice(this.activeAssigneeList.indexOf(member.id), 1);
            } else {
                this.activeAssigneeList.push(member.id);
            }
            this.$emit('filterAssignee', this.activeAssigneeList);
        },
        filterMyTask() {
            this.activeAssigneeList = [];
            if(this.activeMyTask) {
                this.activeMyTask = false;
                this.$emit('filterAssignee', []);
            } else {
                this.activeMyTask = true;
                const member = JSON.parse(this.userData);
                this.$emit('filterAssignee', [member.id]);
            }
        },
        dateToFormat(date) {
            return moment(date).format("DD-MM-YYYY");
        }
    },
    watch: {
        search: function (val) {
            this.$emit('search', val);
        },
    }
}
</script>

<style scoped>
  .b-avatar.active {
    box-shadow: 0 0 0 2px #7367f0, inset 0 0 0 1px rgb(34 41 47 / 7%);
  }
</style>

