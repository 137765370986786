<template>
  <div>
    <div class="ys-task-attach">
      <h6>Вложения</h6>
      
      <div class="d-flex flex-wrap">
        <div
          class="ys-task-attach__thumb"
          v-for="(attachment, index) in attachments"
        >
          <b-img
            v-if="attachment.mime.includes('image')"
            :src="attachment.url"
            v-bind="imgProps"
            rounded
            class="d-inline-block mr-1 mb-1"
            @click="openGallery(index)"
          />
          <div
            class="ys-task-attach__thumb-video mr-1 mb-1 cursor-pointer"
            v-if="attachment.mime.includes('video')"
            @click="openGallery(index)"
          >
            <b-embed
              type="video"
              aspect="4by3"
              :src="attachment.url"
              allowfullscreen
            ></b-embed>
            <feather-icon icon="PlayIcon" class="ys-task-attach__play-icon text-primary"></feather-icon>
          </div>
          
        </div>
        
        <div class="slk-aatach-loader" v-if="isLoadAttachment">
          <b-spinner
            variant="primary"
          />
        </div>
        
      </div>
    </div>
    <b-modal
      id="modal-attach-gallery"
      ok-only
      ok-title="Закрыть"
      centered
      size="xl"
      title="Вложения"
      @show="showModal"
    >
      <div v-if="attachments.length == 1" class="d-flex align-items-center justify-content-center">
          <b-img
            v-if="attachments[0]['mime'].includes('image')"
            :src="attachments[0]['url']"
            fluid
          />
        <b-embed
          v-if="attachments[0]['mime'].includes('video')"
          type="video"
          aspect="4by3"
          :src="attachments[0]['url']"
          allowfullscreen
          controls
        ></b-embed>
      </div>
      <swiper-thumb v-else :files="attachments" :initSlide="initSlide"></swiper-thumb>
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal, BImg, BEmbed, BSpinner } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SwiperThumb from "../components/SwiperThumb";

export default {
    name: "TaskAttachments",
    props: {
    		attachments: [Array],
		    isLoadAttachment: [Boolean],
    },
		components: {
						SwiperThumb,
				BButton,
				BModal,
        BImg,
        BEmbed,
        BSpinner
		},
		directives: {
				'b-modal': VBModal,
				Ripple,
		},
		data() {
				return {
          imgProps: {
              width: 75,
              height: 75,
              class: 'm1 cursor-pointer',
          },
          initSlide: 0,
				}
		},
    methods: {
		    openGallery(index) {
		    		  this.initSlide = index;
              this.$bvModal.show('modal-attach-gallery');
        },
		    showModal() {
		    		/*setTimeout(()=> {
                  const swiperTop = this.$refs.swiperTop.$swiper
                  const swiperThumbs = this.$refs.swiperThumbs.$swiper
                  swiperTop.controller.control = swiperThumbs
                  swiperThumbs.controller.control = swiperTop
            }, 1000)*/
          
        }
    },
		mounted() {
		
		},
}
</script>

<style scoped>

</style>
